import { Grid } from "@mui/material"
import {
  CameraImagesDiv,
  DivInsideDeviceNameButton,
  FlashingStatusDiv,
  HorizontalLine,
  InProgressDiv,
  ItalicPre,
} from "../../styles/FlashStationStyle/InProgressStyle"
import { DeviceNameButton, Title } from "../../styles/FlashStationStyle/ReadyToFlashStyle"
import { ProgressBar } from "../ProgressBar/ProgressBar"
import { FaiVersion, InProgressTitle } from "../../variables/sharedVariables"
import { DeviceState, EState } from "../../utility/types"
import { ToggleDetailView } from "./ToggleDetailView"
import {
  deviceInfoFromHostname,
  flashingStationTransitionsToFrontendTransitions,
  splitDeviceName,
} from "utility/helpingFunctions"
import { useDeviceStateHook } from "utility/getDeviceState"
import { useEffect, useState } from "react"
import { DeviceNameDiv, ProgressBarDiv, SubContainer, ToggleButtonsDiv } from "styles/CommonStyles"
import { DetailView } from "Components/DetailView/DetailViewWindow"
import { stateTranslations } from "utility/translations"
import { CameraImagesView } from "Components/DetailView/CameraImagesView"

interface ProgressProps {
  deviceData: DeviceState[]
}
export const InProgress = ({ deviceData }: ProgressProps) => {
  const { updateDeviceMonitoringState, deviceMonitoringStates } = useDeviceStateHook()
  const [toggleDetailsButtonId, setToggleDetailsButtonId] = useState<string>("")
  const [toggleCameraImagesId, setToggleCameraImagesId] = useState<string>("")

  const devicesInProgress = deviceData.filter((device: DeviceState) =>
    [
      EState.WAITING_FOR_FLASHING,
      EState.FLASHING,
      EState.IDENTIFYING_DEVICE,
      EState.INSTALLING,
    ].includes(device.state),
  )

  useEffect(() => {
    updateDeviceMonitoringState(devicesInProgress, FaiVersion)
    const interval = setInterval(
      () => updateDeviceMonitoringState(devicesInProgress, FaiVersion),
      1000,
    )
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesInProgress.map((device) => device.device_name || "").join(",")])

  const steps = [
    { label: stateTranslations.waitingForFlashing },
    { label: stateTranslations.flashing },
    { label: stateTranslations.installingOS },
    { label: stateTranslations.installingSevensenseSoftware },
  ]

  const sortedDevicesInProgress = devicesInProgress.sort((a, b) => {
    if (a.device_name && b.device_name) {
      return a.device_name.localeCompare(b.device_name)
    }
    return (a.port_name ?? "").localeCompare(b.port_name ?? "")
  })
  const showDevicesInProgress = sortedDevicesInProgress.map((data) => {
    const flashingStationInProgressTransitionToFrontendTransitions =
      flashingStationTransitionsToFrontendTransitions(data.transitions)
    const device = data.device_name ? deviceInfoFromHostname(data.device_name) : undefined

    return (
      <>
        <div key={data.port_name}>
          <InProgressDiv>
            <DeviceNameDiv>
              <DeviceNameButton
                style={{
                  height: data.device_name ? "auto" : "55px",
                }}
              >
                <DivInsideDeviceNameButton>
                  <ItalicPre>{data.port_name}</ItalicPre>
                  {data.device_name ? splitDeviceName(data.device_name) : ""}
                  {data.device_name ? (
                    <span>
                      {deviceMonitoringStates.get(FaiVersion)?.get(data.device_name)?.message || ""}
                    </span>
                  ) : (
                    ""
                  )}
                </DivInsideDeviceNameButton>
              </DeviceNameButton>
            </DeviceNameDiv>
            <ProgressBarDiv>
              <ProgressBar state={data.state} {...{ steps, isStateFailed: false }} />
            </ProgressBarDiv>
            <ToggleButtonsDiv style={{ paddingLeft: "30px" }}>
              <ToggleDetailView
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                id={data.port_name!}
                {...{
                  toggleDetailsButtonId,
                  setToggleDetailsButtonId,
                  toggleCameraImagesId,
                  setToggleCameraImagesId,
                  data,
                  calibrationStatus: null,
                  ifBootstrapPage: false,
                }}
              />
            </ToggleButtonsDiv>
          </InProgressDiv>
          <CameraImagesDiv>
            {toggleCameraImagesId === data.port_name && (
              <CameraImagesView deviceName={data.port_name} />
            )}
          </CameraImagesDiv>
          <FlashingStatusDiv>
            {toggleDetailsButtonId === data.port_name && (
              <DetailView
                transitions={flashingStationInProgressTransitionToFrontendTransitions}
                ifBootstrapPage={false}
                device={device}
              />
            )}
          </FlashingStatusDiv>
        </div>
        <HorizontalLine />
      </>
    )
  })

  return (
    <SubContainer>
      <Grid alignItems="flex-start" container spacing={1}>
        <Grid container direction="column" item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Title>{InProgressTitle}</Title>
          </Grid>
          <Grid item xs={12} justifyContent="space-around">
            {showDevicesInProgress}
          </Grid>
        </Grid>
      </Grid>
    </SubContainer>
  )
}
